.background-video {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.echarts-for-react.diagnostics-maintenance-chart {
  height: 30px!important;
  width: 100%!important;
}

.echarts-for-react.fusion-trend-chart {
  height: 140px!important;
}

.react-datepicker__input-container input {
  background-color: transparent;
  max-width: 88px;
  padding-left: 6px;
  padding-right: 2px;
  font-size: 0.875rem;
  font-weight: 600;
}

.react-datepicker {
  border: 1px solid #E2E8F0;
  border-radius: 16px;
  font-family: 'Montserrat', sans-serif;
}
.react-datepicker__header {
  background-color: #f0f1f2;
}
.react-datepicker__navigation {
  top: 8px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #E2E8F0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.react-datepicker__day--selected {
  background-color: #7071f3;
}

.react-datepicker__day--keyboard-selected {
  background-color: #7071f3;
  color: #FFF;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #7071f3;
}

.visual-ai-datepicker .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  border-color: rgba(1, 21, 43, 1)
}

.visual-ai-datepicker .react-datepicker__navigation-icon--previous::before {
  left: 0px;
}

.visual-ai-datepicker .react-datepicker__navigation-icon--next::before {
  left: -16px;
}

.visual-ai-datepicker .react-datepicker {
  border: 0;
}

.visual-ai-datepicker .react-datepicker__day--selected:hover{
  background-color: #7071f3;
}

.visual-ai-datepicker .react-datepicker .react-datepicker__current-month {
  padding-bottom: 16px;
  padding-top: 16px;
  font-size: 1rem;
  font-weight: 500;
}

.react-datepicker__navigation {
  top: 24px;
}

.visual-ai-datepicker .react-datepicker .react-datepicker__header  {
  background-color: transparent;
  border-bottom: 0;
}

.visual-ai-datepicker .react-datepicker .react-datepicker__day {
  width: 36px;
  line-height: 36px;
  font-weight: 500;
  border-radius: 100%;
}
.visual-ai-datepicker .react-datepicker .react-datepicker__day-name {
  width: 36px;
  line-height: 36px;
  font-weight: 500;
  border-radius: 100%;
  color: rgba(85, 104, 125, 1);

}

.js-plotly-plot .plotly .modebar-container {
  bottom: 32px!important;
  top: auto!important;;
}

.js-plotly-plot .plotly .modebar-group {
  display: flex!important;
  padding-left: 0px!important;
 }

.js-plotly-plot .plotly .modebar-group .modebar-btn {
  font-size: 18px!important;
  margin-left: 5px!important;
  margin-right: 5px!important;
}

.rock-size-plot .js-plotly-plot .plotly .modebar-container {
  bottom: -5px!important;
  top: auto!important;
}

.belt-misalignment-plot .js-plotly-plot .plotly .modebar-container {
  bottom: -5px!important;
  top: auto!important;
}

.tooltip-box {
  position: relative;
  width: 100%;
  cursor: none;
}

.tooltip-box::before {
  content: attr(data-tooltip);
  position: absolute;
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 600;
  border-radius: 8px;
  font-size: 12px;
  pointer-events: none;
  white-space: nowrap;
  transform: translate(-50%, -100%);
  transition: opacity 0.2s;
  opacity: 0;
  z-index: 9999;
}

.tooltip-box:hover::before {
  opacity: 1;
}

.tooltip-box::before {
  top: var(--y, 0);
  left: var(--x, 0);
}

.evidence-player-wrapper {
  position: relative;
  padding-top: calc(((100vh ) / 3) * 2 - 258px);
}

.evidence-player {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 12px;
}

.evidence-player video {
  border-radius: 12px;
}

